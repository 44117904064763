<template>
  <div class="supplier-analysis" v-loading.fullscreen.lock="pageLoading" element-loading-text="加载中..." element-loading-background="rgba(255, 255, 255, 0.8)">
    <div class="page-header">
      <h2>供应商分析</h2>
    </div>
    <div class="page-content">
      <!-- 筛选条件 -->
      <div class="filter-bar">
        <div class="filter-item">
          <span class="filter-label">时间范围</span>
          <div class="time-selector">
            <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="handleDateRangeChange" size="small" class="date-range-picker">
            </el-date-picker>
          </div>
        </div>
      </div>

      <!-- 数据概览卡片 -->
      <el-row :gutter="20" class="dashboard-cards">
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card class="data-card" shadow="hover">
            <div class="card-title">总供应商数量</div>
            <div class="card-value">{{ dashboardData.total_suppliers_count || 0 }}</div>
            <div class="card-growth" :class="getGrowthClass(dashboardData.total_suppliers_growth)">
              <i :class="getGrowthIconClass(dashboardData.total_suppliers_growth)"></i>
              {{ formatPercentage(dashboardData.total_suppliers_growth) }}
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card class="data-card" shadow="hover">
            <div class="card-title">当前周期活跃供应商</div>
            <div class="card-value">{{ dashboardData.active_suppliers_count || 0 }}</div>
            <div class="card-growth" :class="getGrowthClass(dashboardData.active_suppliers_growth)">
              <i :class="getGrowthIconClass(dashboardData.active_suppliers_growth)"></i>
              {{ formatPercentage(dashboardData.active_suppliers_growth) }}
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card class="data-card" shadow="hover">
            <div class="card-title">应付金额</div>
            <div class="card-value">{{ formatAmount(dashboardData.payable_amount) }}</div>
            <div class="card-growth" :class="getGrowthClass(dashboardData.payable_growth)">
              <i :class="getGrowthIconClass(dashboardData.payable_growth)"></i>
              {{ formatPercentage(dashboardData.payable_growth) }}
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card class="data-card" shadow="hover">
            <div class="card-title">结算净额</div>
            <div class="card-value">{{ formatAmount(dashboardData.settlement_amount) }}</div>
            <div class="card-growth" :class="getGrowthClass(dashboardData.settlement_growth)">
              <i :class="getGrowthIconClass(dashboardData.settlement_growth)"></i>
              {{ formatPercentage(dashboardData.settlement_growth) }}
            </div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 分析图表 -->
      <el-row :gutter="20" class="chart-row">
        <el-col :span="12">
          <el-card class="chart-card" shadow="hover">
            <div slot="header">合作品牌分布</div>
            <div v-if="!dashboardData.payment_status || dashboardData.payment_status.length === 0" class="empty-chart">
              <i class="el-icon-data-analysis"></i>
              <p>暂无合作品牌分布</p>
            </div>
            <div class="chart" ref="paymentStatusChart"></div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="chart-card" shadow="hover">
            <div slot="header">订单来源区域</div>
            <div v-if="!dashboardData.order_sources || dashboardData.order_sources.length === 0" class="empty-chart">
              <i class="el-icon-data-analysis"></i>
              <p>暂无订单来源区域</p>
            </div>
            <div class="chart" ref="orderSourcesChart"></div>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="chart-row">
        <el-col :span="12">
          <el-card class="chart-card" shadow="hover">
            <div slot="header">客户行业分布</div>
            <div v-if="!dashboardData.industries || dashboardData.industries.length === 0" class="empty-chart">
              <i class="el-icon-data-analysis"></i>
              <p>暂无客户行业分布</p>
            </div>
            <div class="chart" ref="industriesChart"></div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="chart-card" shadow="hover">
            <div slot="header">供应商产品分布</div>
            <div v-if="!dashboardData.policy_products || dashboardData.policy_products.length === 0" class="empty-chart">
              <i class="el-icon-data-analysis"></i>
              <p>暂无供应商产品分布</p>
            </div>
            <div class="chart" ref="policyProductsChart"></div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 供应商排名表格 -->
      <el-card class="ranking-table" shadow="hover">
        <div slot="header" class="table-header">
          <span>供应商排名</span>
          <div class="time-selector">
            <!-- 时间范围选择器 -->
            <el-date-picker v-model="rankingDateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="handleRankingDateRangeChange" size="small" class="date-range-picker">
            </el-date-picker>
          </div>
        </div>
        <el-table v-loading="tableLoading" :data="supplierRanking" border style="width: 100%">
          <el-table-column type="index" label="排名" width="80"></el-table-column>
          <el-table-column prop="name" label="供应商名称" min-width="180"></el-table-column>
          <el-table-column prop="count" label="合作次数" width="120">
            <template slot-scope="scope">
              {{ scope.row.count }}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="合作金额" width="180">
            <template slot-scope="scope">
              {{ formatAmount(scope.row.amount) }}
            </template>
          </el-table-column>
          <el-table-column prop="growth" label="同比增长" width="120">
            <template slot-scope="scope">
              <span :class="getGrowthClass(scope.row.growth)">
                {{ formatPercentage(scope.row.growth) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="pagination-container">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(pagination.page)" :page-sizes="[10, 20, 50, 100]" :page-size="parseInt(pagination.page_size)" layout="total, sizes, prev, pager, next, jumper" :total="parseInt(pagination.total)">
          </el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { getSupplierDashboard, getSupplierRanking } from '@/api';

export default {
  name: 'SupplierAnalysis',
  data() {
    // 获取当前日期
    const today = new Date();
    const defaultEndDate = today.toISOString().split('T')[0]; // 格式：YYYY-MM-DD

    // 计算默认开始日期（当前年份的1月1日）
    const defaultStartDate = `${today.getFullYear()}-01-01`;

    return {
      // 日期范围选择器
      dateRange: [defaultStartDate, defaultEndDate],
      rankingDateRange: [defaultStartDate, defaultEndDate],

      // 仪表盘查询参数
      queryParams: {
        start_date: defaultStartDate,
        end_date: defaultEndDate
      },
      // 仪表盘数据
      dashboardData: {
        total_suppliers: 0,
        active_suppliers_count: 0,
        active_suppliers_growth: 0,
        payable_amount: 0,
        payable_growth: 0,
        settlement_amount: 0,
        settlement_growth: 0,
        payment_status: [],
        order_sources: [],
        industries: [],
        policy_products: []
      },
      // 图表实例
      charts: {
        paymentStatus: null,
        orderSources: null,
        industries: null,
        policyProducts: null
      },
      // 供应商排名查询参数
      rankingParams: {
        start_date: defaultStartDate,
        end_date: defaultEndDate,
        limit: 20,
        page: 1
      },
      // 供应商排名数据
      supplierRanking: [],
      // 分页数据
      pagination: {
        total: 0,
        page: 1,
        page_size: 20,
        total_pages: 0
      },
      // 加载状态
      loading: false,
      tableLoading: false,
      // 全局加载状态
      pageLoading: false,
      // 重试计数器
      _chartInitRetryCount: 0,
      // 新增的日期范围选择器
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    }
  },
  methods: {
    // 验证并格式化请求参数
    validateAndFormatParams(params) {
      const result = { ...params };

      // 验证日期参数
      const minDate = '2023-12-31';
      const today = new Date().toISOString().split('T')[0];

      // 验证并格式化开始日期
      if (!result.start_date) {
        result.start_date = `${new Date().getFullYear()}-01-01`;
      } else if (result.start_date < minDate) {
        result.start_date = minDate;
      }

      // 验证并格式化结束日期
      if (!result.end_date) {
        result.end_date = today;
      } else if (result.end_date > today) {
        result.end_date = today;
      }

      // 确保开始日期不大于结束日期
      if (result.start_date > result.end_date) {
        const temp = result.start_date;
        result.start_date = result.end_date;
        result.end_date = temp;
      }

      // 添加其他非时间参数 (例如分页参数)
      if (result.limit !== undefined) {
        result.limit = parseInt(result.limit);
        if (isNaN(result.limit) || result.limit < 1) {
          result.limit = 20;
        }
        if (result.limit > 100) {
          result.limit = 100;
        }
      }

      if (result.page !== undefined) {
        result.page = parseInt(result.page);
        if (isNaN(result.page) || result.page < 1) {
          result.page = 1;
        }
      }

      return result;
    },

    // 获取仪表盘数据
    async fetchDashboardData() {
      this.loading = true;
      try {
        // 确保请求参数格式正确
        const validParams = this.validateAndFormatParams(this.queryParams);

        try {
          // 使用api函数调用
          const response = await getSupplierDashboard(validParams);

          // 检查响应是否为空
          if (!response) {
            throw new Error('返回数据为空');
          }

          // 初始化默认值，确保图表所需数据存在
          const defaultData = {
            total_suppliers: 0,
            active_suppliers_count: 0,
            active_suppliers_growth: 0,
            payable_amount: 0,
            payable_growth: 0,
            settlement_amount: 0,
            settlement_growth: 0,
            payment_status: [],
            order_sources: [],
            industries: [],
            policy_products: []
          };

          // 提取仪表盘数据
          let dashboardData = null;

          // 标准的API响应格式: { code: "0", message: "成功", data: {...} }
          if (response && (response.code === "0" || response.code === 0)) {
            dashboardData = response.data;
          }
          // 兜底逻辑：直接处理响应对象
          else {
            if (response.data) {
              dashboardData = response.data;
            } else {
              // 如果没有data字段，则使用整个响应对象
              dashboardData = response;
            }
          }

          // 确保各数组字段为数组类型
          const arrayFields = ['payment_status', 'order_sources', 'industries', 'policy_products'];
          arrayFields.forEach(field => {
            if (dashboardData && dashboardData[field] && !Array.isArray(dashboardData[field])) {
              dashboardData[field] = [];
            }
          });

          // 合并返回的数据和默认数据，确保所有必需字段都存在
          this.dashboardData = { ...defaultData, ...dashboardData };

          // 使用setTimeout确保DOM已更新
          setTimeout(() => {
            this.initCharts();
          }, 100);
        } catch (requestError) {
          throw new Error(`API请求失败: ${requestError.message || '未知错误'}`);
        }
      } catch (error) {
        this.$message.error(`获取仪表盘数据失败: ${error.message || '未知错误'}`);

        // 发生错误时设置一些默认数据，保证UI正常显示
        this.setDefaultDashboardData();
      } finally {
        this.loading = false;
      }
    },

    // 设置默认仪表盘数据
    setDefaultDashboardData() {
      this.dashboardData = {
        total_suppliers: 0,
        active_suppliers_count: 0,
        active_suppliers_growth: 0,
        payable_amount: 0,
        payable_growth: 0,
        settlement_amount: 0,
        settlement_growth: 0,
        payment_status: [
          { name: '暂无数据', count: 1 }
        ],
        order_sources: [
          { name: '暂无数据', count: 1 }
        ],
        industries: [
          { name: '暂无数据', count: 1 }
        ],
        policy_products: [
          { name: '暂无数据', count: 1 }
        ]
      };

      // 初始化图表显示默认数据
      setTimeout(() => {
        this.initCharts();
      }, 100);
    },

    // 获取供应商排名数据
    async fetchSupplierRanking() {
      this.tableLoading = true;
      try {
        // 确保请求参数格式正确
        const validParams = this.validateAndFormatParams(this.rankingParams);

        try {
          // 使用api函数调用
          const response = await getSupplierRanking(validParams);

          // 检查响应是否为空
          if (!response) {
            throw new Error('返回数据为空');
          }

          // 处理响应数据
          let supplierRankingData = [];
          let paginationData = null;

          // 标准的API响应格式: { code: "0", message: "成功", data: {...} }
          if (response && (response.code === "0" || response.code === 0)) {
            // 检查是否有嵌套的supplier_ranking数组
            if (response.data && response.data.supplier_ranking && Array.isArray(response.data.supplier_ranking)) {
              supplierRankingData = response.data.supplier_ranking;
            }
            // 直接是数组的情况
            else if (Array.isArray(response.data)) {
              supplierRankingData = response.data;
            }

            // 提取分页数据
            if (response.data && response.data.pagination) {
              paginationData = response.data.pagination;
            }
          }
          // 兜底逻辑：直接处理响应对象
          else {
            // 处理可能的不同响应格式
            if (Array.isArray(response)) {
              supplierRankingData = response;
            } else if (response.supplier_ranking && Array.isArray(response.supplier_ranking)) {
              supplierRankingData = response.supplier_ranking;
            } else if (response.data) {
              if (Array.isArray(response.data)) {
                supplierRankingData = response.data;
              } else if (response.data.supplier_ranking && Array.isArray(response.data.supplier_ranking)) {
                supplierRankingData = response.data.supplier_ranking;
              }
            }

            // 提取分页数据
            if (response.pagination) {
              paginationData = response.pagination;
            } else if (response.data && response.data.pagination) {
              paginationData = response.data.pagination;
            }
          }

          // 确保数据是数组类型
          if (!Array.isArray(supplierRankingData)) {
            supplierRankingData = [];
          }

          // 更新供应商排名数据
          this.supplierRanking = supplierRankingData;

          // 更新分页数据
          if (paginationData) {
            this.pagination = {
              total: parseInt(paginationData.total || 0),
              page: parseInt(paginationData.page || 1),
              page_size: parseInt(paginationData.page_size || 20),
              total_pages: parseInt(paginationData.total_pages || 1)
            };
          } else {
            // 如果没有分页信息，则使用默认值
            this.pagination = {
              total: this.supplierRanking.length,
              page: 1,
              page_size: 20,
              total_pages: Math.ceil(this.supplierRanking.length / 20) || 1
            };
          }
        } catch (requestError) {
          throw new Error(`API请求失败: ${requestError.message || '未知错误'}`);
        }
      } catch (error) {
        this.$message.error('获取供应商排名失败: ' + (error.message || '未知错误'));
        this.supplierRanking = [];
        this.pagination = {
          total: 0,
          page: 1,
          page_size: 20,
          total_pages: 0
        };
      } finally {
        this.tableLoading = false;
      }
    },
    // 初始化图表
    initCharts() {
      try {
        // 添加重试计数器，防止无限循环
        if (!this._chartInitRetryCount) {
          this._chartInitRetryCount = 0;
        }

        // 最大重试次数，超过后不再尝试
        if (this._chartInitRetryCount >= 5) {
          return;
        }

        this._chartInitRetryCount++;

        // 首先检查DOM元素是否存在
        const charts = [
          { name: '付款状况图表', ref: this.$refs.paymentStatusChart },
          { name: '订单来源图表', ref: this.$refs.orderSourcesChart },
          { name: '行业分布图表', ref: this.$refs.industriesChart },
          { name: '政策产品图表', ref: this.$refs.policyProductsChart }
        ];

        // 检查DOM元素是否存在
        const missingCharts = charts.filter(chart => !chart.ref);
        if (missingCharts.length > 0) {
          // 如果是第一次尝试，给予更长的等待时间
          const delay = this._chartInitRetryCount === 1 ? 300 : 100;

          setTimeout(() => {
            this.initCharts();
          }, delay);
          return;
        }

        // 重置重试计数器
        this._chartInitRetryCount = 0;

        // 初始化各个图表
        this.initPaymentStatusChart();
        this.initOrderSourcesChart();
        this.initIndustriesChart();
        this.initPolicyProductsChart();
      } catch (error) {
        // 出错时也重置计数器，防止下次调用仍然受限
        this._chartInitRetryCount = 0;
      }
    },
    // 初始化付款状况分布图表
    initPaymentStatusChart() {
      try {
        const chartDom = this.$refs.paymentStatusChart;
        if (!chartDom) {
          return;
        }

        // 销毁旧的图表实例
        if (this.charts.paymentStatus) {
          this.charts.paymentStatus.dispose();
        }

        // 创建新的图表实例
        this.charts.paymentStatus = echarts.init(chartDom);

        // 处理数据
        let data = this.dashboardData.payment_status || [];
        // 如果没有数据，显示一个空状态
        if (data.length === 0) {
          data = [{ name: '暂无数据', count: 1 }];
        }

        const chartData = data.map(item => ({
          name: item.name,
          value: item.count
        }));

        // 设置图表选项
        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            show: false  // 隐藏图例
          },
          series: [
            {
              name: '合作品牌',
              type: 'pie',
              radius: ['40%', '60%'],
              center: ['50%', '45%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 4,  // 添加圆角
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                position: 'outside',
                formatter: function(params) {
                  // 根据名称长度调整换行
                  const name = params.name;
                  const value = params.value;
                  const percent = params.percent + '%';
                  
                  if (name.length > 8) {
                    return name.substring(0, 8) + '\n' + 
                           (name.length > 8 ? name.substring(8) + '\n' : '') + 
                           value + ' (' + percent + ')';
                  }
                  return name + '\n' + value + ' (' + percent + ')';
                },
                textStyle: {
                  fontSize: 12,
                  color: '#606266'
                }
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 14,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true,
                length: 15,
                length2: 10,
                smooth: true  // 平滑引导线
              },
              data: chartData
            }
          ]
        };

        // 渲染图表
        this.charts.paymentStatus.setOption(option);
      } catch (error) {
        // 处理错误，但不输出到控制台
      }
    },
    // 初始化订单来源分布图表
    initOrderSourcesChart() {
      try {
        const chartDom = this.$refs.orderSourcesChart;
        if (!chartDom) {
          return;
        }

        // 销毁旧的图表实例
        if (this.charts.orderSources) {
          this.charts.orderSources.dispose();
        }

        // 创建新的图表实例
        this.charts.orderSources = echarts.init(chartDom);

        // 处理数据
        let data = this.dashboardData.order_sources || [];
        // 如果没有数据，显示一个空状态
        if (data.length === 0) {
          data = [{ name: '暂无数据', count: 1 }];
        }

        const chartData = data.map(item => ({
          name: item.name,
          value: item.count
        }));

        // 设置图表选项
        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            show: false  // 隐藏图例
          },
          series: [
            {
              name: '订单来源',
              type: 'pie',
              radius: ['40%', '60%'],
              center: ['50%', '45%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 4,  // 添加圆角
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                position: 'outside',
                formatter: function(params) {
                  // 根据名称长度调整换行
                  const name = params.name;
                  const value = params.value;
                  const percent = params.percent + '%';
                  
                  if (name.length > 8) {
                    return name.substring(0, 8) + '\n' + 
                           (name.length > 8 ? name.substring(8) + '\n' : '') + 
                           value + ' (' + percent + ')';
                  }
                  return name + '\n' + value + ' (' + percent + ')';
                },
                textStyle: {
                  fontSize: 12,
                  color: '#606266'
                }
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 14,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true,
                length: 15,
                length2: 10,
                smooth: true  // 平滑引导线
              },
              data: chartData
            }
          ]
        };

        // 渲染图表
        this.charts.orderSources.setOption(option);
      } catch (error) {
        // 处理错误，但不输出到控制台
      }
    },
    // 初始化行业分布图表
    initIndustriesChart() {
      try {
        const chartDom = this.$refs.industriesChart;
        if (!chartDom) {
          return;
        }

        // 销毁旧的图表实例
        if (this.charts.industries) {
          this.charts.industries.dispose();
        }

        // 创建新的图表实例
        this.charts.industries = echarts.init(chartDom);

        // 处理数据
        let data = this.dashboardData.industries || [];
        // 如果没有数据，显示一个空状态
        if (data.length === 0) {
          data = [{ name: '暂无数据', count: 1 }];
        }

        const chartData = data.map(item => ({
          name: item.name,
          value: item.count
        }));

        // 设置图表选项
        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            show: false  // 隐藏图例
          },
          series: [
            {
              name: '行业分布',
              type: 'pie',
              radius: '50%',
              center: ['50%', '45%'],
              data: chartData,
              itemStyle: {
                borderRadius: 4,  // 添加圆角
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                position: 'outside',
                formatter: function(params) {
                  // 根据名称长度调整换行
                  const name = params.name;
                  const value = params.value;
                  const percent = params.percent + '%';
                  
                  if (name.length > 8) {
                    return name.substring(0, 8) + '\n' + 
                           (name.length > 8 ? name.substring(8) + '\n' : '') + 
                           value + ' (' + percent + ')';
                  }
                  return name + '\n' + value + ' (' + percent + ')';
                },
                textStyle: {
                  fontSize: 12,
                  color: '#606266'
                }
              },
              labelLine: {
                show: true,
                length: 15,
                length2: 10,
                smooth: true  // 平滑引导线
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };

        // 渲染图表
        this.charts.industries.setOption(option);
      } catch (error) {
        // 处理错误，但不输出到控制台
      }
    },
    // 初始化政策供应商产品分布图表
    initPolicyProductsChart() {
      try {
        const chartDom = this.$refs.policyProductsChart;
        if (!chartDom) {
          return;
        }

        // 销毁旧的图表实例
        if (this.charts.policyProducts) {
          this.charts.policyProducts.dispose();
        }

        // 创建新的图表实例
        this.charts.policyProducts = echarts.init(chartDom);

        // 处理数据
        let data = this.dashboardData.policy_products || [];
        // 如果没有数据，显示一个空状态
        if (data.length === 0) {
          data = [{ name: '暂无数据', count: 1 }];
        }

        const names = data.map(item => item.name);
        const values = data.map(item => item.count);

        // 设置图表选项
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            show: false  // 隐藏图例
          },
          grid: {
            top: '5%',
            left: '3%',
            right: '15%',  // 增加右侧空间以容纳标签
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLabel: {
              fontSize: 12
            }
          },
          yAxis: {
            type: 'category',
            data: names,
            axisLabel: {
              interval: 0,
              fontSize: 12,
              color: '#606266',
              formatter: function(value) {
                // 如果文本过长，截断并添加省略号
                if (value.length > 10) {
                  return value.substring(0, 10) + '...';
                }
                return value;
              }
            }
          },
          series: [
            {
              name: '产品数量',
              type: 'bar',
              data: values,
              itemStyle: {
                color: '#409EFF'  // 使用Element UI的主色调
              },
              barWidth: '60%',  // 控制柱子宽度
              label: {
                show: true,
                position: 'right',
                distance: 5,  // 增加标签与柱子的距离
                fontSize: 12,
                color: '#606266',
                formatter: '{c}'
              }
            }
          ]
        };

        // 渲染图表
        this.charts.policyProducts.setOption(option);
      } catch (error) {
      }
    },
    // 处理仪表盘查询
    handleDashboardSearch() {
      this.fetchDashboardData();
    },
    // 处理排名查询
    handleRankingSearch() {
      this.rankingParams.page = 1;
      this.fetchSupplierRanking();
    },
    // 处理所有查询
    handleSearchAll() {
      // 开启全局加载状态
      this.pageLoading = true;

      // 将查询参数从上部同步到下部排名查询参数
      this.rankingParams.start_date = this.queryParams.start_date;
      this.rankingParams.end_date = this.queryParams.end_date;

      // 同步日期范围选择器
      this.rankingDateRange = [this.queryParams.start_date, this.queryParams.end_date];

      // 获取数据
      Promise.all([
        this.fetchDashboardData(),
        this.fetchSupplierRanking()
      ]).finally(() => {
        // 关闭全局加载状态
        this.pageLoading = false;
      });
    },
    // 重置查询参数
    resetQuery() {
      // 开启全局加载状态
      this.pageLoading = true;

      const currentYear = new Date().getFullYear();
      const today = new Date().toISOString().split('T')[0]; // 格式：YYYY-MM-DD
      const defaultStartDate = `${currentYear}-01-01`;

      this.queryParams = {
        start_date: defaultStartDate,
        end_date: today
      };
      this.rankingParams = {
        start_date: defaultStartDate,
        end_date: today,
        limit: 20,
        page: 1
      };

      // 重置日期范围选择器
      this.dateRange = [defaultStartDate, today];
      this.rankingDateRange = [defaultStartDate, today];

      // 获取数据
      Promise.all([
        this.fetchDashboardData(),
        this.fetchSupplierRanking()
      ]).finally(() => {
        // 关闭全局加载状态
        this.pageLoading = false;
      });
    },
    // 处理每页数量变化
    handleSizeChange(val) {
      // 开启全局加载状态
      this.pageLoading = true;

      this.rankingParams.limit = val;
      this.pagination.page_size = val;
      this.pagination.page = 1;
      this.rankingParams.page = 1;

      // 立即更新供应商排名数据
      this.fetchSupplierRanking().finally(() => {
        // 关闭全局加载状态
        this.pageLoading = false;
      });
    },
    // 处理页码变化
    handleCurrentChange(val) {
      // 开启全局加载状态
      this.pageLoading = true;

      this.rankingParams.page = val;
      this.pagination.page = val;

      // 立即更新供应商排名数据
      this.fetchSupplierRanking().finally(() => {
        // 关闭全局加载状态
        this.pageLoading = false;
      });
    },
    // 格式化金额
    formatAmount(amount) {
      if (amount === null || amount === undefined || amount === '') return '¥0.00';

      // 尝试将输入转换为数字
      const numAmount = parseFloat(amount);
      if (isNaN(numAmount)) return '¥0.00';

      // 格式化为带千分位的金额
      return '¥' + numAmount.toLocaleString('zh-CN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    // 格式化百分比
    formatPercentage(value) {
      if (value === null || value === undefined || value === '') return '0%';

      // 尝试将输入转换为数字
      const numValue = parseFloat(value);
      if (isNaN(numValue)) return '0%';

      return numValue.toFixed(2) + '%';
    },
    // 获取增长率的类名
    getGrowthClass(growth) {
      if (growth === null || growth === undefined || growth === '') return '';

      const value = parseFloat(growth);
      if (isNaN(value)) return '';

      if (value > 0) return 'growth-up';
      if (value < 0) return 'growth-down';
      return '';
    },
    // 获取增长率的图标类名
    getGrowthIconClass(growth) {
      if (growth === null || growth === undefined || growth === '') return '';

      const value = parseFloat(growth);
      if (isNaN(value)) return '';

      if (value > 0) return 'el-icon-top';
      if (value < 0) return 'el-icon-bottom';
      return '';
    },
    // 窗口大小变化时，重新调整图表大小
    handleResize() {
      Object.values(this.charts).forEach(chart => {
        if (chart) {
          try {
            chart.resize();
          } catch (error) {
            // 处理错误，但不输出到控制台
          }
        }
      });
    },
    // 处理日期范围变化
    handleDateRangeChange(dateRange) {
      if (dateRange && dateRange.length === 2) {
        // 开启全局加载状态
        this.pageLoading = true;

        this.queryParams.start_date = dateRange[0];
        this.queryParams.end_date = dateRange[1];

        // 同步上下部分的日期选择
        this.rankingParams.start_date = dateRange[0];
        this.rankingParams.end_date = dateRange[1];
        this.rankingDateRange = [...dateRange];

        // 获取数据
        Promise.all([
          this.fetchDashboardData(),
          this.fetchSupplierRanking()
        ]).finally(() => {
          // 关闭全局加载状态
          this.pageLoading = false;
        });
      }
    },
    // 处理排名日期范围变化
    handleRankingDateRangeChange(dateRange) {
      if (dateRange && dateRange.length === 2) {
        // 开启全局加载状态
        this.pageLoading = true;

        this.rankingParams.start_date = dateRange[0];
        this.rankingParams.end_date = dateRange[1];

        // 获取数据
        this.fetchSupplierRanking().finally(() => {
          // 关闭全局加载状态
          this.pageLoading = false;
        });
      }
    }
  },
  mounted() {
    // 开启全局加载状态
    this.pageLoading = true;

    // 初始化日期范围选择器的值
    this.dateRange = [this.queryParams.start_date, this.queryParams.end_date];
    this.rankingDateRange = [this.rankingParams.start_date, this.rankingParams.end_date];

    // 为了确保DOM元素已经渲染完成，延迟加载数据
    setTimeout(() => {
      // 获取仪表盘数据和供应商排名数据
      Promise.all([
        // 获取仪表盘数据，如果失败则显示默认数据
        this.fetchDashboardData().catch(() => {
          this.setDefaultDashboardData();
        }),

        // 获取供应商排名数据，如果失败则显示空列表
        this.fetchSupplierRanking().catch(() => {
          this.supplierRanking = [];
          this.pagination = {
            total: 0,
            page: 1,
            page_size: 20,
            total_pages: 0
          };
        })
      ]).finally(() => {
        // 关闭全局加载状态
        this.pageLoading = false;
      });
    }, 100);

    // 响应窗口大小变化，重新调整图表大小
    window.addEventListener('resize', this.handleResize);

    // 监听主题变化，重新渲染图表
    window.addEventListener('theme-change', () => {
      setTimeout(() => {
        this.initCharts();
      }, 100);
    });
  },
  beforeDestroy() {
    // 清除图表实例
    Object.values(this.charts).forEach(chart => {
      if (chart) {
        try {
          chart.dispose();
        } catch (error) {
          // 处理错误，但不输出到控制台
        }
      }
    });

    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('theme-change', this.initCharts);
  }
}
</script>

<style scoped>
.supplier-analysis {
  padding: 20px;
}

.page-header {
  margin-bottom: 20px;
}

.filter-bar {
  margin-bottom: 20px;
  background-color: #f5f7fa;
  padding: 12px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.filter-item {
  display: flex;
  align-items: center;
}

.filter-label {
  margin-right: 10px;
  color: #606266;
  font-size: 14px;
}

.time-selector {
  display: flex;
  align-items: center;
}

.time-type-select {
  width: 120px;
  margin-right: 10px;
}

.time-picker {
  width: 150px;
}

.date-range-picker {
  width: 320px;
}

.year-picker {
  width: 120px;
  margin-right: 10px;
}

.quarter-picker {
  width: 120px;
}

.data-card {
  margin-bottom: 20px;
  text-align: center;
}

.card-title {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
}

.card-value {
  font-size: 24px;
  font-weight: bold;
  color: #303133;
  margin-bottom: 10px;
}

.card-growth {
  font-size: 14px;
}

.growth-up {
  color: #67c23a;
}

.growth-down {
  color: #f56c6c;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {

  /* 过滤栏适配 */
  .filter-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-item {
    margin-bottom: 10px;
    width: 100%;
  }

  .time-selector {
    flex-wrap: wrap;
  }

  .time-type-select,
  .time-picker {
    margin-bottom: 10px;
  }

  /* 图表和卡片适配 */
  .chart-row .el-col {
    width: 100% !important;
  }

  .dashboard-cards .el-col {
    width: 100% !important;
  }

  .el-form-item__content {
    width: 100%;
  }

  .el-select {
    width: 100%;
  }
}

.chart-card {
  margin-bottom: 20px;
  height: 400px;
  position: relative;
}

.chart {
  height: 350px;
}

.empty-chart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #909399;
}

.empty-chart i {
  font-size: 48px;
  margin-bottom: 10px;
}

.empty-chart p {
  margin: 0;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 移动端适配表格排名 */
@media screen and (max-width: 768px) {
  .table-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .table-header>div {
    margin-top: 10px;
    width: 100%;
  }

  .table-header .el-select {
    width: 100%;
  }

  .pagination-container .el-pagination {
    white-space: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination-container .el-pagination>* {
    margin-bottom: 10px;
  }
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}
</style>